import React from 'react';
import IndicadorServico from './IndicadorServico';

const MultiplosIndicadores = ({
  indicadoresEscolhidos = [],
  indicadores,
  handleChange,
  servicos,
  registoCarregado,
  mostrarColunaTotal = true,
}) => {
  return (
    <React.Fragment>
      {indicadoresEscolhidos.map((ind, _id) => {
        return (
          <IndicadorServico
            key={_id}
            codigo={ind}
            servicos={servicos}
            valorInicial={registoCarregado[ind]}
            handleChange={handleChange}
            indicador={indicadores.filter((e) => e.codigo === ind)[0]}
            mostrarColunaTotal={mostrarColunaTotal}
          />
        );
      })}
    </React.Fragment>
  );
};

export default MultiplosIndicadores;
