import React, { useState } from 'react';

import SurveyGroup from '../../surveys/components/survey-group';
import MultiplosIndicadores from '../components/MultiplosIndicadores';

const ADULTOS = ({ handleChange, registoCarregado, indicadores }) => {
  const [registoParcial, setRegistoParcial] = useState({});

  const listaIndicadores = [
    'A_1_AD_1_1',
    'A_1_AD_1_2',
    'A_1_AD_2_3',
    'A_1_AD_2_4',
    'A_1_AD_2_5',
    'A_1_AD_3_1',
    'A_1_AD_3_2',
    'A_1_AD_4_1',
    'A_1_AD_4_2',
    'A_2_AD_1_1',
    'A_2_AD_1_2',
    'A_2_AD_1_3',
    'A_2_AD_2_1',
    'A_2_AD_2_2',
    'A_2_AD_2_3',
    'A_2_AD_2_4',
    'A_2_AD_3_1',
    'A_2_AD_3_2',
    'A_2_AD_3_3',
    'A_2_AD_3_4',
    'A_2_AD_3_5',
    'A_2_AD_4_1',
    'A_2_AD_4_2',
    'A_2_AD_5_1',
    'A_2_AD_5_2',
    'A_3_AD_1_1',
    'A_3_AD_2_1',
    'A_3_AD_3_2',
    'A_3_AD_4_3',
    'A_3_AD_4_4',
    'A_4_AD_1_1',
    'A_4_AD_2_1',
    'A_4_AD_3_1',
    'A_4_AD_3_2',
    'A_4_AD_3_3',
    'A_4_AD_4_1',
    'A_4_AD_4_2',
    'A_4_AD_5_1',
    'A_4_AD_6_1',
    'A_4_AD_6_2',
    //'A_4_AD_6_3',
    'A_4_AD_6_4',
    'A_5_AD_1_1',
    'A_5_AD_1_2',
    'A_5_AD_2_1',
    'A_5_AD_2_2',
    'A_5_AD_3_1',
    'A_5_AD_3_2',
    'A_5_AD_3_3',
    'A_6_AD_1_1',
    'A_6_AD_1_2',
    'A_6_AD_2_1',
    'A_6_AD_3_1',
    'A_6_AD_3_2',
    'A_6_AD_3_3',
    'A_6_AD_3_4',
    'A_6_AD_3_5',
    'A_6_AD_4_1',
    'A_7_AD_1_1',
    'A_7_AD_2_1',
    'A_7_AD_2_2',
    'A_7_AD_3_7',
    'A_7_AD_3_8',
    'A_7_AD_3_9',
    'A_8_AD_1_1',
    'A_8_AD_1_2',
    'A_8_AD_2_1',
    'A_8_AD_2_2',
    'A_8_AD_3_1',
    'A_8_AD_3_2',
    'B_1_1',
    'B_1_2',
    'B_2_2',
    'B_2_3',
    'B_2_4',
    'B_3_3',
    'B_3_6',
    'B_4_3',
    'B_5_1',
    'B_6_1',
    'B_6_2',
    'B_7_2',
    'B_8_1',
    'B_8_2',
    'B_9_2',
    'B_9_3',
    'B_9_4',
    'B_10_1',
    'B_10_2',
    'B_11_1',
    'B_11_2',
    'B_11_3',
    'B_12_1',
    'B_12_2',
    'B_12_4',
    'B_13_1',
    'B_15_1',
    'B_15_4',
    'B_19_1',
    'B_19_2',
    'B_21_3',
    'B_21_4',
  ];

  const servicos = [
    'CACI_OF',
    'CACI_SPS',
    'USO',
    'FORUM',
    'GAPRIC',
    'SAF',
    'AR',
  ];

  const updateRegistoParcial = (val, codigo) => {
    let obj = registoParcial;

    obj = {
      ...obj,
      [codigo]: {
        ...val,
      },
    };
    setRegistoParcial(obj);
    handleChange(obj);
  };

  return (
    <SurveyGroup classes="ml-5 mr-5 mt-2">
      <table className="table">
        <thead>
          <tr>
            <th scope="col" style={{ width: '5%' }}>
              Código
            </th>
            <th scope="col" style={{ width: '50%' }}>
              Indicador
            </th>
            {servicos.map((serv, id) => {
              return (
                <th key={id} scope="col" style={{ width: '5%' }}>
                  {serv}
                </th>
              );
            })}
            <th scope="col" style={{ width: '7%' }}>
              TOTAL
            </th>
          </tr>
        </thead>
        <tbody>
          <MultiplosIndicadores
            indicadoresEscolhidos={listaIndicadores}
            indicadores={indicadores}
            registoCarregado={registoCarregado}
            servicos={servicos}
            handleChange={updateRegistoParcial}
          />
        </tbody>
      </table>
    </SurveyGroup>
  );
};

export default ADULTOS;
