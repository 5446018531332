import React, { useState } from 'react';

import SurveyGroup from '../../surveys/components/survey-group';
import MultiplosIndicadores from '../components/MultiplosIndicadores';

const CT_SA = ({ handleChange, registoCarregado, indicadores }) => {
  const [registoParcial, setRegistoParcial] = useState({});

  const listaIndicadores = [
    'B_9_1',
    'B_9_5',
    'B_11_4',
    'B_12_3',
    'B_12_4',
    'B_12_5',
    'B_14_1',
    'B_14_2',
    'B_14_3',
    'B_15_2',
    'B_15_3',
    'B_16_1',
    'B_16_2',
    'B_17_1',
    'B_17_2',
    'B_18_1',
    'B_18_2',
    'B_19_3',
    'B_19_4',
    'B_19_5',
    'B_19_6',
    'B_19_7',
    'B_19_8',
    'B_19_9',
    'B_19_10',
    'B_19_11',
    'B_20_1',
    'B_20_2',
    'B_21_1',
    'B_21_2',
    'B_21_5',
    'B_21_6',
    'C_1_1',
    'C_1_2',
    'C_2_1',
    'C_2_2',
    'C_3_1',
    'C_3_2',
    'C_3_3',
    'C_3_4',
  ];

  const servicos = ['CT', 'SA'];

  const updateRegistoParcial = (val, codigo) => {
    let obj = registoParcial;

    obj = {
      ...obj,
      [codigo]: {
        ...val,
      },
    };
    setRegistoParcial(obj);
    handleChange(obj);
  };

  return (
    <SurveyGroup classes="ml-5 mr-5 mt-2">
      <table className="table">
        <thead>
          <tr>
            <th scope="col" style={{ width: '5%' }}>
              Código
            </th>
            <th scope="col" style={{ width: '50%' }}>
              Indicador
            </th>
            {servicos.map((serv, id) => {
              return (
                <th key={id} scope="col" style={{ width: '5%' }}>
                  {serv}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          <MultiplosIndicadores
            indicadoresEscolhidos={listaIndicadores}
            indicadores={indicadores}
            registoCarregado={registoCarregado}
            servicos={servicos}
            handleChange={updateRegistoParcial}
            mostrarColunaTotal={false}
          />
        </tbody>
      </table>
    </SurveyGroup>
  );
};

export default CT_SA;
