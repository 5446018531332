import React, { useState, useContext, useEffect } from 'react';
import { Modal } from 'antd';

import NovoRegistoModal from './components/NovoRegistoModal';
import SelectRegisto from './components/SelectRegisto';
import { useHttpClient } from '../shared/hooks/http-hook';
import { AuthContext } from '../shared/context/auth-context';
import SelectPartial from './components/SelectPartial';

//PARTIALS
import ADULTOS from './partials/ADULTOS';
import CRI_IPI from './partials/CRI_IPI';
import CT_SA from './partials/CT_SA';
import FP_CRE from './partials/FP_CRE';

const Registos = () => {
  const [modalVisible, setModalVisible] = useState(false);
  const [registoEscolhido, setRegistoEscolhido] = useState(null);

  const [registoCarregado, setRegistoCarregado] = useState({});
  const [indicadores, setIndicadores] = useState({});
  const [generatedView, setGeneratedView] = useState(null);
  const [partialEscolhido, setPartialEscolhido] = useState('');
  const [shouldRender, setShouldRender] = useState(false);

  const auth = useContext(AuthContext);
  // eslint-disable-next-line
  const { isLoading, error, sendRequest, clearError } = useHttpClient();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/indicadores`,
          'GET',
          undefined,
          {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + auth.token,
          }
        );
        setIndicadores(responseData.data);
      } catch (err) {}
    };
    fetchData();
  }, [sendRequest, auth.token]);

  useEffect(() => {
    const fetchRegisto = async () => {
      if (!registoEscolhido) return;

      setRegistoCarregado(null);

      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/registosservicos/${registoEscolhido}`,
          'GET',
          undefined,
          {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + auth.token,
          }
        );
        setRegistoCarregado(responseData.data);
      } catch (err) {}
    };
    fetchRegisto();
  }, [sendRequest, auth.token, registoEscolhido, shouldRender]);

  const updateRegistoCarregado = (val) => {
    let obj = registoCarregado;

    obj = {
      ...obj,
      ...val,
    };
    setRegistoCarregado(obj);
  };

  const updateRegistoBD = async () => {
    await sendRequest(
      `${process.env.REACT_APP_BACKEND_URL}/registosservicos/${registoEscolhido}`,
      'POST',
      JSON.stringify(registoCarregado),
      {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + auth.token,
      }
    );
    Modal.success({
      title: 'Mensagem',
      content: 'Registo guardado com sucesso!',
    });
  };

  const generatePartial = (partialName) => {
    let PartialComponent = null;

    setPartialEscolhido(partialName);

    switch (partialName) {
      case 'ADULTOS':
        PartialComponent = ADULTOS;
        break;
      case 'CRI_IPI':
        PartialComponent = CRI_IPI;
        break;
      case 'CT_SA':
        PartialComponent = CT_SA;
        break;
      case 'FP_CRE':
        PartialComponent = FP_CRE;
        break;
      default:
        setGeneratedView(<div></div>);
    }

    if (PartialComponent) {
      setGeneratedView(
        <React.Fragment>
          <PartialComponent
            handleChange={updateRegistoCarregado}
            registoCarregado={registoCarregado}
            indicadores={indicadores}
          />
        </React.Fragment>
      );
    }
  };

  return (
    <div className="mb-5 mt-4 d-block align-content-center w-100">
      <div className="container">
        <div className="form-group row survey-card survey-bg center m-0 p-2">
          <div className="row">
            <NovoRegistoModal
              visible={modalVisible}
              handleCancel={() => setModalVisible(false)}
              toggleRefresh={() => setShouldRender(!shouldRender)}
            />
            <SelectRegisto
              onChange={(val) => {
                setPartialEscolhido('');
                generatePartial('');
                setRegistoEscolhido(val);
              }}
              shouldRender={shouldRender}
            />
            <button
              className="btn btn-secondary m-2"
              onClick={() => setModalVisible(true)}
            >
              Novo Registo
            </button>
          </div>
          {registoEscolhido && (
            <React.Fragment>
              <SelectPartial
                value={partialEscolhido}
                handleChange={generatePartial}
              />
              <button
                className="form-control btn btn-success mt-3"
                onClick={updateRegistoBD}
              >
                Guardar Registo
              </button>
            </React.Fragment>
          )}
        </div>
      </div>

      {generatedView}
    </div>
  );
};

export default Registos;
