import React, { useContext } from 'react';
import { NavLink, useHistory } from 'react-router-dom';

import { AuthContext } from '../../context/auth-context';

import './NavLinks.css';

const NavLinks = (props) => {
  const auth = useContext(AuthContext);
  const history = useHistory();

  return (
    <ul className="nav-links">
      {auth.isLoggedIn && (
        <li>
          <NavLink to="/indicadores">CONSULTA</NavLink>

          <NavLink to="/registos">INSERIR</NavLink>
        </li>
      )}

      {!auth.isLoggedIn && (
        <li>
          <NavLink to="/login">ENTRAR</NavLink>
        </li>
      )}
      {auth.isLoggedIn && (
        <li>
          <button
            onClick={() => {
              auth.logout();
              history.push('/login');
            }}
          >
            SAIR
          </button>
        </li>
      )}
    </ul>
  );
};

export default NavLinks;
