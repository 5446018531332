import React, { useEffect, useState, useContext } from 'react';

//ag-grid
import { AgGridReact, AgGridColumn } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import 'ag-grid-enterprise';

import { gridConfig } from '../surveys/pages/surveys/common/config';
import { useHttpClient } from '../shared/hooks/http-hook';
import { AuthContext } from '../shared/context/auth-context';
import SelectRegisto from '../registos/components/SelectRegisto';
import SelectPartial from '../registos/components/SelectPartial';

import {
  INDICADORES_ADULTOS,
  SERVICOS_ADULTOS,
  INDICADORES_CRI_IPI,
  SERVICOS_CRI_IPI,
  INDICADORES_CT_SA,
  SERVICOS_CT_SA,
  INDICADORES_FP_CRE,
  SERVICOS_FP_CRE,
} from './consts';

const Indicadores = () => {
  const LARGURACOL_SERVICO = 110;

  const COLUNAS_BASE = [
    {
      ...gridConfig.defaultColumn,
      headerName: 'Código',
      field: 'codigo',
      width: gridConfig.colWidth.date,
    },
    {
      ...gridConfig.defaultColumn,
      headerName: 'Subdominio',
      field: 'subdominio',
      width: 300,
    },
    {
      ...gridConfig.defaultColumn,
      headerName: 'Indicador',
      field: 'indicador',
      width: 400,
    },

    {
      ...gridConfig.defaultColumn,
      headerName: 'EQUASS PR',
      field: 'equass_pr',
      width: 150,
    },
    {
      ...gridConfig.defaultColumn,
      headerName: 'EQUASS CR',
      field: 'equass_cr',
      width: 150,
    },
    {
      ...gridConfig.defaultColumn,
      headerName: 'IE OB',
      field: 'ie_ob',
      width: LARGURACOL_SERVICO,
    },
  ];
  const COLUNAS_SERVICOS = [
    {
      ...gridConfig.defaultColumn,
      headerName: 'CACI_OF',
      field: 'CACI_OF',
      width: LARGURACOL_SERVICO,
    },
    {
      ...gridConfig.defaultColumn,
      headerName: 'CACI_SPS',
      field: 'CACI_SPS',
      width: LARGURACOL_SERVICO,
    },
    {
      ...gridConfig.defaultColumn,
      headerName: 'USO',
      field: 'USO',
      width: LARGURACOL_SERVICO,
    },
    {
      ...gridConfig.defaultColumn,
      headerName: 'FORUM',
      field: 'FORUM',
      width: LARGURACOL_SERVICO,
    },
    {
      ...gridConfig.defaultColumn,
      headerName: 'GAPRIC',
      field: 'GAPRIC',
      width: LARGURACOL_SERVICO,
    },
    {
      ...gridConfig.defaultColumn,
      headerName: 'SAF',
      field: 'SAF',
      width: LARGURACOL_SERVICO,
    },
    {
      ...gridConfig.defaultColumn,
      headerName: 'AR',
      field: 'AR',
      width: LARGURACOL_SERVICO,
    },
    {
      ...gridConfig.defaultColumn,
      headerName: 'CRI',
      field: 'CRI',
      width: LARGURACOL_SERVICO,
    },
    {
      ...gridConfig.defaultColumn,
      headerName: 'IPI',
      field: 'IPI',
      width: LARGURACOL_SERVICO,
    },
    {
      ...gridConfig.defaultColumn,
      headerName: 'CT',
      field: 'CT',
      width: LARGURACOL_SERVICO,
    },
    {
      ...gridConfig.defaultColumn,
      headerName: 'SA',
      field: 'SA',
      width: LARGURACOL_SERVICO,
    },
    {
      ...gridConfig.defaultColumn,
      headerName: 'FP',
      field: 'FP',
      width: LARGURACOL_SERVICO,
    },
    {
      ...gridConfig.defaultColumn,
      headerName: 'CRE',
      field: 'CRE',
      width: LARGURACOL_SERVICO,
    },
  ];
  const COLUNA_TOTAL = [
    {
      ...gridConfig.defaultColumn,
      headerName: 'TOTAL',
      field: 'total',
      width: LARGURACOL_SERVICO,
    },
  ];

  // eslint-disable-next-line
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [rowData, setRowData] = useState([]);
  const [columns, setColumns] = useState(
    COLUNAS_BASE.concat(COLUNA_TOTAL, COLUNAS_SERVICOS)
  );
  const [servicosEscolhidos, setServicosEscolhidos] = useState(
    SERVICOS_ADULTOS.concat(SERVICOS_CRI_IPI, SERVICOS_CT_SA, SERVICOS_FP_CRE)
  );
  const [indicadoresEscolhidos, setIndicadoresEscolhidos] = useState(
    INDICADORES_ADULTOS.concat(
      INDICADORES_CRI_IPI,
      INDICADORES_CT_SA,
      INDICADORES_FP_CRE
    )
  );
  const [registoEscolhido, setRegistoEscolhido] = useState(null);

  const auth = useContext(AuthContext);

  const atualizarColunas = (servicos, total) => {
    //console.log('ATUALIZAR COLUNAS: ', total);
    setColumns([]);
    if (!total) {
      setColumns(
        COLUNAS_BASE.concat(
          COLUNAS_SERVICOS.filter((col) => servicos.includes(col.field))
        )
      );
    } else {
      setColumns(
        COLUNAS_BASE.concat(
          COLUNA_TOTAL,
          COLUNAS_SERVICOS.filter((col) => servicos.includes(col.field))
        )
      );
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        let registoData;
        if (registoEscolhido) {
          registoData = await sendRequest(
            `${process.env.REACT_APP_BACKEND_URL}/registosservicos/${registoEscolhido}`
          );
        }

        //console.log('INDICADORES: ', indicadoresEscolhidos);
        //console.log('SERVIÇOS: ', servicosEscolhidos);

        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/indicadores`
        );
        setRowData(() => {
          return responseData.data
            .filter((indicador) =>
              indicadoresEscolhidos.includes(indicador.codigo)
            )
            .map((indicador) => {
              let total = '-';
              if (registoData && registoData.data) {
                if (!registoData.data[indicador.codigo]) {
                  //console.log('INDICADOR VAZIO');
                } else {
                  let valoresFiltrados = [];

                  servicosEscolhidos.forEach((serv) => {
                    if (
                      registoData.data[indicador.codigo] &&
                      registoData.data[indicador.codigo][serv]
                    ) {
                      valoresFiltrados.push(
                        parseFloat(registoData.data[indicador.codigo][serv])
                      );
                    }
                  });

                  total = valoresFiltrados.reduce((a, b) => a + b, 0);

                  let media =
                    typeof indicador.media === 'undefined'
                      ? true
                      : indicador.media;
                  let percentagem =
                    typeof indicador.percentagem === 'undefined'
                      ? true
                      : indicador.percentagem;
                  let mostrarTotal =
                    typeof indicador.mostrarTotal === 'undefined'
                      ? true
                      : indicador.mostrarTotal;

                  if (media) {
                    total = (total / valoresFiltrados.length).toFixed(2);
                  }

                  if (total === 'NaN') return '';

                  if (percentagem) total += ' %';

                  if (total === 0 || !mostrarTotal) return '';
                }
              }
              return {
                codigo: indicador.codigo.replaceAll('_', '.'),
                subdominio: indicador.subdominio,
                indicador: indicador.descricao,
                servicos: indicador.servicos,
                equass_pr: indicador.equass_pr,
                equass_cr: indicador.equass_cr,
                ie_ob: indicador.ie_ob,
                total: total,
                CACI_OF:
                  registoData && registoData.data[indicador.codigo]
                    ? registoData.data[indicador.codigo].CACI_OF
                    : '',
                CACI_SPS:
                  registoData && registoData.data[indicador.codigo]
                    ? registoData.data[indicador.codigo].CACI_SPS
                    : '',
                USO:
                  registoData && registoData.data[indicador.codigo]
                    ? registoData.data[indicador.codigo].USO
                    : '',
                FORUM:
                  registoData && registoData.data[indicador.codigo]
                    ? registoData.data[indicador.codigo].FORUM
                    : '',
                GAPRIC:
                  registoData && registoData.data[indicador.codigo]
                    ? registoData.data[indicador.codigo].GAPRIC
                    : '',
                SAF:
                  registoData && registoData.data[indicador.codigo]
                    ? registoData.data[indicador.codigo].SAF
                    : '',
                AR:
                  registoData && registoData.data[indicador.codigo]
                    ? registoData.data[indicador.codigo].AR
                    : '',
                CRI:
                  registoData && registoData.data[indicador.codigo]
                    ? registoData.data[indicador.codigo].CRI
                    : '',
                IPI:
                  registoData && registoData.data[indicador.codigo]
                    ? registoData.data[indicador.codigo].IPI
                    : '',
                CT:
                  registoData && registoData.data[indicador.codigo]
                    ? registoData.data[indicador.codigo].CT
                    : '',
                SA:
                  registoData && registoData.data[indicador.codigo]
                    ? registoData.data[indicador.codigo].SA
                    : '',
                FP:
                  registoData && registoData.data[indicador.codigo]
                    ? registoData.data[indicador.codigo].FP
                    : '',
                CRE:
                  registoData && registoData.data[indicador.codigo]
                    ? registoData.data[indicador.codigo].CRE
                    : '',
              };
            });
        });
      } catch (err) {}
    };
    fetchData();
  }, [
    sendRequest,
    auth.token,
    registoEscolhido,
    servicosEscolhidos,
    indicadoresEscolhidos,
  ]);

  return (
    <div style={{ width: '99%' }}>
      <div className="row ml-5">
        <SelectRegisto
          label="Escolha Registo"
          onChange={(val) => setRegistoEscolhido(val)}
        />
        <SelectPartial
          label="Base"
          todos
          handleChange={(v) => {
            switch (v) {
              case 'ADULTOS':
                setServicosEscolhidos(SERVICOS_ADULTOS);
                atualizarColunas(SERVICOS_ADULTOS, true);
                setIndicadoresEscolhidos(INDICADORES_ADULTOS);
                break;
              case 'CRI_IPI':
                setServicosEscolhidos(SERVICOS_CRI_IPI);
                atualizarColunas(SERVICOS_CRI_IPI, true);
                setIndicadoresEscolhidos(INDICADORES_CRI_IPI);
                break;
              case 'CT_SA':
                setServicosEscolhidos(SERVICOS_CT_SA);
                atualizarColunas(SERVICOS_CT_SA, false);
                setIndicadoresEscolhidos(INDICADORES_CT_SA);
                break;
              case 'FP_CRE':
                setServicosEscolhidos(SERVICOS_FP_CRE);
                atualizarColunas(SERVICOS_FP_CRE, true);
                setIndicadoresEscolhidos(INDICADORES_FP_CRE);
                break;
              case 'TODOS':
                setServicosEscolhidos(
                  SERVICOS_ADULTOS.concat(
                    SERVICOS_CRI_IPI,
                    SERVICOS_CT_SA,
                    SERVICOS_FP_CRE
                  )
                );

                atualizarColunas(
                  SERVICOS_ADULTOS.concat(
                    SERVICOS_CRI_IPI,
                    SERVICOS_CT_SA,
                    SERVICOS_FP_CRE
                  ),
                  true
                );
                setIndicadoresEscolhidos(
                  INDICADORES_ADULTOS.concat(
                    INDICADORES_CRI_IPI,
                    INDICADORES_CT_SA,
                    INDICADORES_FP_CRE
                  )
                );
                break;
              default:
                console.log('OUTRO');
            }
          }}
        />
      </div>
      <div
        className="ag-theme-alpine"
        style={{
          height: `${window.innerHeight - 140}px`,
          width: 'auto',
          marginTop: '0px',
          padding: '20px',
        }}
      >
        <AgGridReact
          rowData={rowData}
          rowGroupPanelShow={'always'}
          rowSelection={true}
          gridOptions={{
            statusBar: {
              statusPanels: [
                {
                  statusPanel: 'agTotalAndFilteredRowCountComponent',
                  align: 'left',
                },
              ],
            },
          }}
        >
          {columns.map((column) => (
            <AgGridColumn {...column} key={column.field} />
          ))}
        </AgGridReact>
      </div>
    </div>
  );
};

export default Indicadores;
