import React from 'react';

const SelectPartial = ({
  handleChange,
  value,
  todos = false,
  label = 'Escolha: ',
}) => {
  const changeValue = (e) => {
    handleChange(e.target.value);
  };

  return (
    <select
      onChange={changeValue}
      className="form-control m-2 ml-4"
      name="selected_survey"
      id="partials"
      value={value}
      style={{ width: '650px' }}
    >
      {!todos ? (
        <option value="" disabled>
          {label}
        </option>
      ) : (
        <option value="TODOS">Todos</option>
      )}
      <option value="ADULTOS">Adultos</option>
      <option value="CRI_IPI">CRI e IPI</option>
      <option value="CT_SA">CT e SA</option>
      <option value="FP_CRE">FP e CRE</option>
    </select>
  );
};

export default SelectPartial;
