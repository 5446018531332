import React, { useState, useContext } from 'react';
import { Button, Modal } from 'antd';

import { AuthContext } from '../../shared/context/auth-context';
import { useHttpClient } from '../../shared/hooks/http-hook';

const NovoRegistoModal = (props) => {
  // eslint-disable-next-line
  const { isLoading, error, sendRequest, clearError } = useHttpClient();

  const auth = useContext(AuthContext);
  const [nomeRegisto, setNomeRegisto] = useState('');

  const { handleCancel, toggleRefresh } = props;

  const guardarRegisto = async () => {
    if (nomeRegisto.length < 1) return;
    console.log('A guardar registo com o nome: ', nomeRegisto);

    const responseData = await sendRequest(
      `${process.env.REACT_APP_BACKEND_URL}/registosservicos`,
      'POST',
      JSON.stringify({
        nome: nomeRegisto,
      }),
      {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + auth.token,
      }
    );

    console.log(responseData.data);
    toggleRefresh();
    cancelarRegisto();
  };

  const cancelarRegisto = () => {
    setNomeRegisto('');
    handleCancel();
  };

  let { visible } = props;
  return (
    <div>
      <Modal
        visible={visible}
        title={'Novo Registo'}
        //onOk={handleSave}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={cancelarRegisto}>
            Cancelar
          </Button>,
          <Button key="submit" onClick={guardarRegisto} type="primary">
            Guardar
          </Button>,
        ]}
      >
        <input
          type="text"
          value={nomeRegisto}
          name="nome"
          onChange={(e) => setNomeRegisto(e.target.value)}
          className="form-control"
          placeholder="Nome"
        />
      </Modal>
    </div>
  );
};

export default NovoRegistoModal;
