export const INDICADORES_ADULTOS = [
  'A_1_AD_1_1',
  'A_1_AD_1_2',
  'A_1_AD_2_3',
  'A_1_AD_2_4',
  'A_1_AD_2_5',
  'A_1_AD_3_1',
  'A_1_AD_3_2',
  'A_1_AD_4_1',
  'A_1_AD_4_2',
  'A_2_AD_1_1',
  'A_2_AD_1_2',
  'A_2_AD_1_3',
  'A_2_AD_2_1',
  'A_2_AD_2_2',
  'A_2_AD_2_3',
  'A_2_AD_2_4',
  'A_2_AD_3_1',
  'A_2_AD_3_2',
  'A_2_AD_3_3',
  'A_2_AD_3_4',
  'A_2_AD_3_5',
  'A_2_AD_4_1',
  'A_2_AD_4_2',
  'A_2_AD_5_1',
  'A_2_AD_5_2',
  'A_3_AD_1_1',
  'A_3_AD_2_1',
  'A_3_AD_3_2',
  'A_3_AD_4_3',
  'A_3_AD_4_4',
  'A_4_AD_1_1',
  'A_4_AD_2_1',
  'A_4_AD_3_1',
  'A_4_AD_3_2',
  'A_4_AD_3_3',
  'A_4_AD_4_1',
  'A_4_AD_4_2',
  'A_4_AD_5_1',
  'A_4_AD_6_1',
  'A_4_AD_6_2',
  //'A_4_AD_6_3',
  'A_4_AD_6_4',
  'A_5_AD_1_1',
  'A_5_AD_1_2',
  'A_5_AD_2_1',
  'A_5_AD_2_2',
  'A_5_AD_3_1',
  'A_5_AD_3_2',
  'A_5_AD_3_3',
  'A_6_AD_1_1',
  'A_6_AD_1_2',
  'A_6_AD_2_1',
  'A_6_AD_3_1',
  'A_6_AD_3_2',
  'A_6_AD_3_3',
  'A_6_AD_3_4',
  'A_6_AD_3_5',
  'A_6_AD_4_1',
  'A_7_AD_1_1',
  'A_7_AD_2_1',
  'A_7_AD_2_2',
  'A_7_AD_3_7',
  'A_7_AD_3_8',
  'A_7_AD_3_9',
  'A_8_AD_1_1',
  'A_8_AD_1_2',
  'A_8_AD_2_1',
  'A_8_AD_2_2',
  'A_8_AD_3_1',
  'A_8_AD_3_2',
  'B_1_1',
  'B_1_2',
  'B_2_2',
  'B_2_3',
  'B_2_4',
  'B_3_3',
  'B_3_6',
  'B_4_3',
  'B_5_1',
  'B_6_1',
  'B_6_2',
  'B_7_2',
  'B_8_1',
  'B_8_2',
  'B_9_2',
  'B_9_3',
  'B_9_4',
  'B_10_1',
  'B_10_2',
  'B_11_1',
  'B_11_2',
  'B_11_3',
  'B_12_1',
  'B_12_2',
  'B_12_4',
  'B_13_1',
  'B_15_1',
  'B_15_4',
  'B_19_1',
  'B_19_2',
  'B_21_3',
  'B_21_4',
];

export const SERVICOS_ADULTOS = [
  'CACI_OF',
  'CACI_SPS',
  'USO',
  'FORUM',
  'GAPRIC',
  'SAF',
  'AR',
];

export const INDICADORES_CRI_IPI = [
  'A_1_IJ_1_1',
  'A_1_IJ_1_2',
  'A_1_IJ_1_3',
  'A_1_IJ_2_1',
  'A_1_IJ_2_2',
  'A_1_IJ_2_3',
  'A_1_IJ_2_4',
  'A_1_IJ_3_1',
  'A_1_IJ_4_1',
  'A_2_IJ_1_1',
  'A_2_IJ_1_2',
  'A_2_IJ_1_3',
  'A_2_IJ_2_1',
  'A_2_IJ_2_2',
  'A_2_IJ_3_1',
  'A_2_IJ_4_1',
  'A_2_IJ_5_1',
  'A_2_IJ_5_2',
  'A_3_IJ_1_1',
  'A_3_IJ_2_1',
  'A_3_IJ_2_2',
  'A_3_IJ_2_3',
  'A_3_IJ_3_1',
  'A_3_IJ_3_2',
  'A_3_IJ_3_3',
  'A_3_IJ_3_5',
  'A_3_IJ_3_6',
  'A_3_IJ_4_1',
  'A_3_IJ_4_2',
  'A_4_IJ_1_1',
  'A_4_IJ_1_2',
  'A_4_IJ_2_1',
  'A_4_IJ_2_2',
  'A_4_IJ_2_3',
  'A_4_IJ_3_1',
  'A_4_IJ_3_2',
  'A_4_IJ_4_1',
  'A_4_IJ_4_2',
  'A_4_IJ_4_3',
  'A_4_IJ_4_4',
  'A_5_IJ_1_1',
  'A_5_IJ_1_2',
  'A_5_IJ_2_1',
  'A_5_IJ_2_2',
  'A_5_IJ_2_3',
  'A_5_IJ_2_4',
  'A_5_IJ_2_5',
  'A_5_IJ_3_1',
  'A_6_IJ_1_1',
  'A_6_IJ_2_1',
  'A_6_IJ_3_1',
  'A_6_IJ_3_2',
  'A_6_IJ_3_3',
  'A_6_IJ_4_1',
  'A_7_IJ_1_1',
  'A_7_IJ_1_2',
  'A_7_IJ_1_3',
  'A_7_IJ_1_4',
  'A_7_IJ_1_5',
  'A_7_IJ_2_1',
  'A_7_IJ_2_2',
  'A_8_IJ_1_1',
  'A_8_IJ_1_2',
  'A_8_IJ_2_1',
  'A_8_IJ_2_2',
  'A_8_IJ_3_1',
  'A_8_IJ_3_2',
  'A_8_IJ_3_3',
  'A_8_IJ_3_4',
  'B_1_1',
  'B_2_1',
  'B_2_2',
  'B_2_4',
  'B_3_1',
  'B_3_2',
  'B_3_3',
  'B_4_1',
  'B_4_2',
  'B_6_1',
  'B_6_2',
  'B_8_1',
  'B_8_2',
  'B_9_2',
  'B_9_3',
  'B_9_4',
  'B_9_5',
  'B_10_2',
  'B_11_1',
  'B_11_2',
  'B_11_3',
  'B_12_1',
  'B_15_1',
  'B_15_4',
  'B_19_1',
  'B_19_2',
  'B_21_4',
];

export const SERVICOS_CRI_IPI = ['CRI', 'IPI'];

export const INDICADORES_CT_SA = [
  'B_9_1',
  'B_9_5',
  'B_11_4',
  'B_12_3',
  'B_12_4',
  'B_12_5',
  'B_14_1',
  'B_14_2',
  'B_14_3',
  'B_15_2',
  'B_15_3',
  'B_16_1',
  'B_16_2',
  'B_17_1',
  'B_17_2',
  'B_18_1',
  'B_18_2',
  'B_19_3',
  'B_19_4',
  'B_19_5',
  'B_19_6',
  'B_19_7',
  'B_19_8',
  'B_19_9',
  'B_19_10',
  'B_19_11',
  'B_20_1',
  'B_20_2',
  'B_21_1',
  'B_21_2',
  'B_21_5',
  'B_21_6',
  'C_1_1',
  'C_1_2',
  'C_2_1',
  'C_2_2',
  'C_3_1',
  'C_3_2',
  'C_3_3',
  'C_3_4',
];

export const SERVICOS_CT_SA = ['CT', 'SA'];

export const INDICADORES_FP_CRE = [
  'A_1_AD_1_1',
  'A_1_AD_1_2',
  'A_1_AD_2_1',
  'A_1_AD_2_2',
  'A_1_AD_2_4',
  'A_1_AD_2_5',
  'A_1_AD_3_1',
  'A_1_AD_3_2',
  'A_1_AD_4_1',
  'A_1_AD_4_2',
  'A_2_AD_1_1',
  'A_2_AD_1_2',
  'A_2_AD_1_3',
  'A_2_AD_2_1',
  'A_2_AD_2_2',
  'A_2_AD_2_3',
  'A_2_AD_2_4',
  'A_2_AD_3_1',
  'A_2_AD_3_2',
  'A_2_AD_3_3',
  'A_2_AD_3_4',
  'A_2_AD_3_5',
  'A_2_AD_4_1',
  'A_2_AD_4_2',
  'A_2_AD_5_1',
  'A_2_AD_5_2',
  'A_3_AD_1_1',
  'A_3_AD_2_1',
  'A_3_AD_3_1',
  'A_3_AD_3_2',
  'A_3_AD_3_3',
  'A_3_AD_3_4',
  'A_3_AD_3_5',
  'A_3_AD_4_1',
  'A_3_AD_4_2',
  'A_3_AD_4_3',
  'A_3_AD_4_4',
  'A_4_AD_1_1',
  'A_4_AD_2_1',
  'A_4_AD_3_1',
  'A_4_AD_3_2',
  'A_4_AD_3_3',
  'A_4_AD_4_1',
  'A_4_AD_4_2',
  'A_4_AD_5_1',
  'A_4_AD_6_1',
  'A_4_AD_6_2',
  'A_4_AD_6_3',
  'A_4_AD_6_4',
  'A_5_AD_1_2',
  'A_5_AD_2_1',
  'A_5_AD_2_2',
  'A_5_AD_3_1',
  'A_5_AD_3_2',
  'A_5_AD_3_3',
  'A_6_AD_1_2',
  'A_6_AD_2_1',
  'A_6_AD_2_2',
  'A_6_AD_3_1',
  'A_6_AD_3_2',
  'A_6_AD_3_3',
  'A_6_AD_3_4',
  'A_6_AD_3_5',
  'A_6_AD_4_1',
  'A_7_AD_1_1',
  'A_7_AD_2_1',
  'A_7_AD_2_2',
  'A_7_AD_3_1',
  'A_7_AD_3_2',
  'A_7_AD_3_3',
  'A_7_AD_3_4',
  'A_7_AD_3_5',
  'A_7_AD_3_6',
  'A_8_AD_1_1',
  'A_8_AD_1_2',
  'A_8_AD_2_1',
  'A_8_AD_2_2',
  'A_8_AD_3_1',
  'A_8_AD_3_2',
  'B_1_1',
  'B_2_2',
  'B_2_3',
  'B_2_4',
  'B_3_3',
  'B_3_4',
  'B_3_5',
  'B_3_6',
  'B_6_1',
  'B_7_1',
  'B_8_1',
  'B_8_2',
  'B_9_2',
  'B_9_3',
  'B_9_4',
  'B_9_6',
  'B_11_1',
  'B_11_2',
  'B_11_3',
  'B_12_1',
  'B_12_2',
  'B_15_1',
  'B_15_4',
  'B_19_1',
  'B_19_2',
  'B_21_4',
];

export const SERVICOS_FP_CRE = ['FP', 'CRE'];
