import React, { useContext, useState, useEffect } from 'react';
import { useHttpClient } from '../../shared/hooks/http-hook';
import { AuthContext } from '../../shared/context/auth-context';

const SelectRegisto = ({ onChange, shouldRender, label = 'Escolha:' }) => {
  const auth = useContext(AuthContext);

  const [registos, setRegistos] = useState([]);
  const [registoEscolhido, setRegistoEscolhido] = useState('');
  // eslint-disable-next-line
  const { isLoading, error, sendRequest, clearError } = useHttpClient();

  useEffect(() => {
    const fetchRegistos = async () => {
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/registosservicos`,
          'GET',
          undefined,
          {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + auth.token,
          }
        );
        setRegistos(responseData.data);
      } catch (err) {}
    };
    fetchRegistos();
  }, [sendRequest, auth.token, shouldRender]);

  const onChangeHandler = (e) => {
    setRegistoEscolhido(e.target.value);
    onChange(e.target.value);
  };
  return (
    <div className="col-xs-2 form-inline m-2">
      <select
        onChange={onChangeHandler}
        className="form-control"
        id="registo"
        name="registo"
        style={{ width: '200px', maxWidth: '220px' }}
        value={registoEscolhido}
      >
        <option value="" disabled>
          {label}
        </option>

        {registos.map((registo) => (
          <option key={registo._id} value={registo._id}>
            {registo.nome}
          </option>
        ))}
      </select>
    </div>
  );
};

export default SelectRegisto;
