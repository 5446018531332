import React from 'react';

const SurveyGroup = ({ name, children, classes = 'm-5 mt-4' }) => {
  return (
    <div className={classes}>
      {name && (
        <div className="form-group row center m-0">
          <h3 className="survey-text">{name}</h3>
        </div>
      )}
      <div className="form-group d-block survey-card survey-bg m-0 pl-4">
        {children}
      </div>
    </div>
  );
};

export default SurveyGroup;
