import React, { useState } from 'react';
import { Input } from 'antd';

const IndicadorServico = ({
  codigo,
  valorInicial,
  handleChange,
  servicos,
  indicador,
  mostrarColunaTotal = true,
}) => {
  const [valor, setValor] = useState(valorInicial || {});

  let media = typeof indicador.media === 'undefined' ? true : indicador.media;
  let percentagem =
    typeof indicador.percentagem === 'undefined' ? true : indicador.percentagem;
  let mostrarTotal =
    typeof indicador.mostrarTotal === 'undefined'
      ? true
      : indicador.mostrarTotal;

  let valores = [];

  servicos.forEach((e) => {
    if (valorInicial && valorInicial[e]) {
      valores.push(parseFloat(valorInicial[e]));
    }
  });

  const calcularTotal = () => {
    let total = valores.reduce((a, b) => a + b, 0);

    if (media) {
      total = (total / valores.length).toFixed(2);
    }

    if (total === 'NaN') return '';

    if (percentagem) total += ' %';

    if (total === 0 || !mostrarTotal) return '';

    return total;
  };

  const [soma, setSoma] = useState(calcularTotal);

  const changeValue = async (value, serv) => {
    const tmpValor = { ...valor, [serv]: value };
    setValor({ ...valor, [serv]: value });

    handleChange(tmpValor, codigo);

    valores = [];
    servicos.forEach((e) => {
      if (tmpValor && tmpValor[e]) {
        valores.push(parseFloat(tmpValor[e]));
      }
    });

    setSoma(calcularTotal);
  };

  if (!indicador) return null;

  return (
    <tr>
      <th scope="row">{indicador.codigo.replaceAll('_', '.')}</th>
      <td>{indicador.descricao}</td>
      {servicos.map((serv, _id) => {
        
        const backgroundColor = !valor[serv] ? '	#FFFF66' : '#FFFFFF'

        return (
          <td key={_id}>
            <Input
              value={valor != null ? valor[serv] || '' : ''}
              title={serv}
              onChange={(e) => {
                changeValue(e.target.value, serv);
              }}
              style={{ width: '65px', backgroundColor }}
            ></Input>
          </td>
        );
      })}
      {mostrarColunaTotal && <td>{soma}</td>}
    </tr>
  );
};

export default IndicadorServico;
